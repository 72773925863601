export const logoNegative = ['608 134', `
  <title>coreui react pro logo</title>
	<g i:extraneous="self">
		<g>
			<g>
				<g>
					<g>
						<path class="st0" d="M76.5,15.67c-1.23-0.91-2.83-1.35-4.72-1.35c-0.75,0-1.56,0.1-2.31,0.24c-0.75,0.14-1.42,0.43-1.98,0.77
							c-0.57,0.39-1.04,0.87-1.42,1.49c-0.33,0.63-0.52,1.4-0.52,2.31c0,0.87,0.24,1.59,0.75,2.12c0.52,0.53,1.18,0.96,2.03,1.35
							c0.85,0.34,1.79,0.63,2.88,0.87c1.08,0.24,2.12,0.48,3.25,0.72c1.08,0.29,2.17,0.58,3.25,0.91c1.08,0.34,2.03,0.82,2.88,1.44
							c0.85,0.63,1.51,1.4,2.03,2.31c0.52,0.91,0.75,2.07,0.75,3.51c0,1.49-0.33,2.79-0.99,3.85c-0.66,1.06-1.51,1.93-2.55,2.6
							c-1.04,0.67-2.22,1.16-3.49,1.49c-1.27,0.29-2.55,0.43-3.82,0.43c-1.56,0-3.02-0.19-4.43-0.58c-1.37-0.39-2.59-1.01-3.68-1.83
							c-1.04-0.82-1.89-1.83-2.5-3.08c-0.61-1.25-0.9-2.74-0.9-4.48h3.49c0,1.2,0.24,2.21,0.66,3.08c0.47,0.87,1.04,1.59,1.79,2.12
							c0.75,0.58,1.6,0.96,2.59,1.25c0.99,0.29,1.98,0.39,3.02,0.39c0.85,0,1.65-0.1,2.5-0.24c0.85-0.14,1.6-0.43,2.26-0.82
							c0.66-0.39,1.23-0.91,1.65-1.59c0.42-0.67,0.61-1.49,0.61-2.55c0-0.96-0.24-1.78-0.75-2.36c-0.52-0.63-1.18-1.11-2.03-1.49
							c-0.85-0.39-1.79-0.67-2.88-0.91c-1.08-0.24-2.17-0.48-3.25-0.72c-1.08-0.24-2.17-0.53-3.25-0.87
							c-1.08-0.34-2.03-0.77-2.88-1.3c-0.85-0.53-1.51-1.25-2.03-2.12c-0.52-0.87-0.75-1.93-0.75-3.22c0-1.44,0.28-2.65,0.85-3.71
							c0.57-1.06,1.32-1.93,2.26-2.6c0.94-0.67,2.03-1.16,3.21-1.49c1.23-0.34,2.45-0.48,3.73-0.48c1.42,0,2.74,0.19,3.96,0.53
							c1.23,0.34,2.31,0.87,3.21,1.64c0.94,0.72,1.65,1.68,2.17,2.79c0.52,1.11,0.8,2.45,0.9,4.04h-3.49
							C78.44,18.03,77.73,16.58,76.5,15.67z"/>
						<path class="st0" d="M86.27,15.91v-4.14h3.3v4.14H86.27z M89.57,19.62v20.5h-3.3v-20.5H89.57z"/>
						<path class="st0" d="M108.63,45.94c-1.51,1.68-3.87,2.5-7.08,2.5c-0.94,0-1.89-0.1-2.88-0.34c-0.99-0.19-1.84-0.58-2.64-1.01
							c-0.8-0.48-1.46-1.11-1.98-1.88c-0.52-0.77-0.8-1.68-0.85-2.84h3.3c0.05,0.63,0.24,1.11,0.57,1.54
							c0.33,0.43,0.75,0.77,1.27,1.01c0.52,0.24,1.04,0.43,1.65,0.58c0.61,0.1,1.18,0.19,1.7,0.19c1.08,0,2.03-0.19,2.78-0.58
							s1.37-0.91,1.89-1.59c0.47-0.67,0.85-1.49,1.08-2.45c0.24-0.96,0.33-2.02,0.33-3.13V36.6h-0.09
							c-0.57,1.25-1.42,2.21-2.59,2.79c-1.13,0.58-2.36,0.91-3.68,0.91c-1.51,0-2.83-0.29-3.92-0.82c-1.13-0.58-2.03-1.3-2.78-2.26
							c-0.75-0.96-1.32-2.02-1.7-3.27c-0.38-1.25-0.57-2.6-0.57-3.99c0-1.2,0.14-2.45,0.47-3.75c0.33-1.3,0.85-2.45,1.56-3.51
							s1.7-1.93,2.88-2.6s2.64-1.01,4.39-1.01c1.27,0,2.45,0.29,3.49,0.87c1.08,0.58,1.89,1.44,2.5,2.55h0.05v-2.94h3.11v18.77
							C110.89,41.75,110.14,44.26,108.63,45.94z M104.43,36.7c0.75-0.48,1.37-1.06,1.84-1.83c0.47-0.77,0.8-1.59,1.04-2.55
							c0.24-0.96,0.33-1.93,0.33-2.84s-0.09-1.78-0.33-2.65c-0.19-0.87-0.52-1.68-0.99-2.36c-0.47-0.72-1.04-1.25-1.79-1.68
							c-0.71-0.43-1.6-0.63-2.64-0.63c-1.08,0-1.98,0.19-2.74,0.63c-0.75,0.43-1.37,0.96-1.84,1.64c-0.47,0.67-0.85,1.49-1.04,2.36
							c-0.24,0.91-0.33,1.83-0.33,2.84c0,0.91,0.09,1.83,0.28,2.79c0.19,0.91,0.47,1.78,0.94,2.5c0.42,0.77,1.04,1.35,1.75,1.83
							c0.71,0.48,1.65,0.67,2.74,0.67C102.73,37.37,103.67,37.13,104.43,36.7z"/>
						<path class="st0" d="M117.83,19.62v3.27h0.09c1.37-2.5,3.54-3.75,6.56-3.75c1.32,0,2.41,0.19,3.3,0.58
							c0.9,0.39,1.6,0.87,2.12,1.54c0.57,0.67,0.94,1.44,1.13,2.36c0.24,0.91,0.33,1.93,0.33,3.03v13.48h-3.3V26.21
							c0-1.25-0.38-2.26-1.08-3.03c-0.71-0.72-1.75-1.11-3.02-1.11c-0.99,0-1.89,0.14-2.64,0.48c-0.75,0.34-1.37,0.77-1.84,1.35
							c-0.47,0.58-0.85,1.25-1.13,2.02c-0.24,0.77-0.38,1.64-0.38,2.55v11.6h-3.3V19.62H117.83z"/>
						<path class="st0" d="M153.02,40.07c-0.57,0.34-1.37,0.53-2.36,0.53c-0.85,0-1.56-0.24-2.03-0.72
							c-0.52-0.48-0.75-1.3-0.75-2.41c-0.9,1.11-1.98,1.93-3.16,2.41c-1.18,0.48-2.5,0.72-3.92,0.72c-0.9,0-1.79-0.1-2.59-0.34
							c-0.8-0.19-1.51-0.53-2.12-1.01c-0.61-0.43-1.08-1.06-1.42-1.78c-0.33-0.72-0.52-1.59-0.52-2.65c0-1.16,0.19-2.12,0.57-2.84
							c0.38-0.72,0.9-1.35,1.56-1.83c0.61-0.48,1.37-0.82,2.17-1.06s1.65-0.43,2.5-0.58c0.9-0.19,1.79-0.34,2.59-0.43
							c0.8-0.1,1.56-0.24,2.17-0.39c0.61-0.19,1.13-0.43,1.46-0.77c0.38-0.34,0.57-0.82,0.57-1.44c0-0.72-0.14-1.35-0.42-1.78
							c-0.28-0.43-0.61-0.77-1.04-1.01c-0.42-0.24-0.9-0.39-1.46-0.48c-0.52-0.1-1.04-0.1-1.56-0.1c-1.42,0-2.55,0.29-3.49,0.82
							c-0.94,0.53-1.46,1.59-1.51,3.08h-3.3c0.05-1.25,0.33-2.36,0.75-3.22c0.47-0.87,1.08-1.59,1.89-2.12
							c0.75-0.53,1.65-0.91,2.69-1.16c0.99-0.24,2.08-0.34,3.21-0.34c0.9,0,1.79,0.05,2.69,0.19s1.7,0.39,2.45,0.82
							c0.71,0.43,1.32,0.96,1.75,1.73c0.42,0.72,0.66,1.68,0.66,2.89v10.54c0,0.77,0.05,1.4,0.14,1.73c0.09,0.39,0.38,0.58,0.9,0.58
							c0.28,0,0.61-0.05,0.99-0.14v2.55H153.02z M147.64,29.53c-0.42,0.34-0.94,0.53-1.65,0.67c-0.66,0.14-1.37,0.24-2.12,0.34
							c-0.75,0.1-1.46,0.19-2.22,0.34c-0.75,0.1-1.42,0.29-2.03,0.58c-0.61,0.24-1.08,0.63-1.46,1.11
							c-0.38,0.48-0.57,1.16-0.57,1.97c0,0.53,0.09,1.01,0.33,1.4c0.24,0.39,0.52,0.67,0.85,0.91c0.33,0.24,0.75,0.39,1.23,0.53
							c0.47,0.1,0.94,0.14,1.46,0.14c1.08,0,2.03-0.14,2.78-0.43c0.75-0.29,1.42-0.67,1.89-1.16c0.47-0.48,0.85-0.96,1.08-1.49
							c0.24-0.53,0.33-1.06,0.33-1.54v-3.37H147.64z"/>
						<path class="st0" d="M163.49,19.62v2.98h-4.01v12.75c0,0.39,0.05,0.72,0.09,0.96c0.05,0.24,0.19,0.43,0.38,0.58
							c0.19,0.14,0.42,0.24,0.75,0.24c0.33,0.05,0.75,0.05,1.27,0.05h1.51v2.98h-2.55c-0.85,0-1.6-0.05-2.22-0.19
							c-0.61-0.1-1.08-0.34-1.46-0.67c-0.38-0.34-0.66-0.77-0.85-1.35c-0.19-0.58-0.28-1.35-0.28-2.31V22.6h-3.44v-2.98h3.44v-6.16
							h3.3v6.16H163.49z"/>
						<path class="st0" d="M179.53,40.12v-3.27h-0.09c-0.71,1.25-1.6,2.21-2.69,2.79c-1.08,0.58-2.36,0.91-3.87,0.91
							c-1.32,0-2.41-0.19-3.3-0.53c-0.9-0.34-1.6-0.87-2.12-1.54c-0.57-0.67-0.94-1.44-1.13-2.36c-0.24-0.91-0.33-1.93-0.33-3.03
							V19.62h3.3v13.91c0,1.25,0.38,2.26,1.08,3.03c0.71,0.72,1.75,1.11,3.02,1.11c0.99,0,1.89-0.14,2.64-0.48
							c0.75-0.34,1.37-0.77,1.84-1.35c0.47-0.58,0.85-1.25,1.13-2.02c0.24-0.77,0.38-1.64,0.38-2.55V19.62h3.3v20.5L179.53,40.12z"
							/>
						<path class="st0" d="M189.44,19.62v4.33h0.09c0.8-1.68,1.79-2.89,2.97-3.71c1.18-0.77,2.64-1.16,4.43-1.11v3.56
							c-1.32,0-2.45,0.19-3.4,0.58s-1.7,0.91-2.26,1.64c-0.57,0.72-0.99,1.59-1.23,2.6c-0.28,1.01-0.38,2.21-0.38,3.51v9.14h-3.3
							V19.62H189.44z"/>
						<path class="st0" d="M211.8,38.82c-1.51,1.16-3.4,1.73-5.66,1.73c-1.6,0-3.02-0.29-4.2-0.77c-1.18-0.53-2.17-1.25-2.97-2.21
							s-1.42-2.07-1.79-3.42c-0.42-1.3-0.61-2.74-0.66-4.33c0-1.54,0.24-2.98,0.71-4.28c0.47-1.3,1.13-2.41,1.98-3.37
							c0.85-0.96,1.84-1.68,3.02-2.21c1.13-0.53,2.41-0.77,3.77-0.77c1.79,0,3.25,0.39,4.43,1.16c1.18,0.77,2.12,1.73,2.83,2.89
							c0.71,1.16,1.23,2.45,1.46,3.8c0.28,1.4,0.38,2.7,0.33,3.95H200c-0.05,0.91,0.09,1.73,0.33,2.55
							c0.24,0.82,0.61,1.54,1.13,2.17c0.52,0.63,1.18,1.11,1.98,1.49s1.75,0.58,2.83,0.58c1.42,0,2.55-0.34,3.44-1.01
							c0.9-0.67,1.46-1.68,1.79-3.03h3.25C214.25,35.93,213.31,37.66,211.8,38.82z M210.99,25.63c-0.28-0.72-0.71-1.35-1.23-1.83
							c-0.52-0.53-1.08-0.91-1.79-1.25c-0.71-0.29-1.46-0.43-2.26-0.43c-0.85,0-1.65,0.14-2.31,0.43c-0.71,0.29-1.27,0.72-1.79,1.25
							c-0.47,0.53-0.9,1.16-1.18,1.83c-0.28,0.72-0.47,1.44-0.52,2.26h11.56C211.47,27.12,211.28,26.35,210.99,25.63z"/>
					</g>
					<g>
						<path class="st1" d="M221.56,14.71l2.97,18.91h0.09l3.16-18.91h5.19l3.16,18.91h0.09l2.97-18.91h5.19l-4.86,25.56h-6.04
							l-3.02-18.53h-0.09l-3.11,18.53h-6.04l-4.86-25.56L221.56,14.71z"/>
						<path class="st1" d="M261.42,18.94h-9.58v6.02h9.01v4.24h-9.01v6.88h9.95v4.24h-15.33v-25.6h14.95V18.94z"/>
						<path class="st1" d="M273.55,14.71c1.08,0,2.12,0.1,3.07,0.34c0.94,0.19,1.75,0.58,2.41,1.06s1.18,1.16,1.56,2.02
							c0.38,0.82,0.57,1.88,0.57,3.08c0,1.4-0.33,2.55-0.99,3.56c-0.66,1.01-1.7,1.68-3.02,2.02v0.05c1.51,0.19,2.69,0.82,3.59,1.83
							c0.9,1.01,1.32,2.41,1.32,4.09c0,1.01-0.14,1.97-0.42,2.84c-0.28,0.91-0.75,1.68-1.42,2.36s-1.51,1.2-2.59,1.64
							c-1.08,0.39-2.41,0.63-3.96,0.63h-9.1V14.71H273.55z M271.42,25.2c1.51,0,2.59-0.24,3.3-0.77c0.71-0.53,1.04-1.4,1.04-2.6
							s-0.33-2.07-0.94-2.6c-0.61-0.48-1.6-0.77-2.97-0.77h-1.93v6.69h1.51V25.2z M271.99,36.46c1.32,0,2.36-0.29,3.16-0.82
							c0.8-0.53,1.18-1.54,1.18-3.03c0-0.72-0.09-1.35-0.33-1.83c-0.24-0.48-0.52-0.87-0.85-1.2c-0.38-0.29-0.8-0.53-1.27-0.63
							c-0.52-0.1-1.04-0.19-1.65-0.19h-2.31v7.7H271.99z"/>
					</g>
				</g>
			</g>
			<g>
				<path class="st2" d="M21.17,35.99c-1.63,1.16-4.62,2.91-8.16,3.65c-3.36,0.7-7.21,0.3-10.24-0.78c-2.44-0.87-3.07-1.29-2.67-1.9
					c0.48-0.73,2.75,1.1,7.06,0.5c3.12-0.43,7.32-2.86,10.74-10.33c-0.04-2.33,0.34-7.58,1.34-11.85c0.76-3.25,1.52-5.62,3.63-5.25
					c1.58,0.42,6.27,1.84,8.19,2.71c1.39,1.03,1.02,3.58,0.07,7.6c-0.57,2.42-1.38,4.86-2.78,7.47c-0.02,1.67,1.17,3.77,2.55,4.88
					c0.45-0.75,1.44-2.46,2.2-4.71c0.04-4.47,0.8-14.34,3.3-13.66c1.52,0.59,5.55,2.38,7.51,3.45c1.25,0.78,1.31,2.74,0.5,5.94
					c-0.51,2-1.26,4.8-2.7,7.63c0,0,0.68,2.53,3.38,3.17c0.8-0.82,1.83-2.42,2.33-3.33c1.95-3.61,2.07-5.38,2.45-7.75
					c0.43-2.71,5.48,2.87,4,7.63c-0.61,1.95-1.59,3.73-2.95,5.35c-1.73,2.07-4.58,3.8-8.78,3.44c-2.11-0.18-3.69-0.75-5.8-2.11
					C31.6,40.83,25.03,40.77,21.17,35.99z"/>
				
					<radialGradient id="SVGID_1_" cx="16.8253" cy="27.49" r="0" gradientTransform="matrix(1.065 0 0 1.0381 0 -1.399)" gradientUnits="userSpaceOnUse">
					<stop  offset="0" style="stop-color:#FBAC2F"/>
					<stop  offset="1" style="stop-color:#B56A28"/>
				</radialGradient>
				<path class="st3" d="M17.92,27.14"/>
				
					<radialGradient id="SVGID_2_" cx="34.1924" cy="15.1515" r="0" gradientTransform="matrix(1.065 0 0 1.0381 0 -1.399)" gradientUnits="userSpaceOnUse">
					<stop  offset="0" style="stop-color:#FBAE2E"/>
					<stop  offset="1" style="stop-color:#B8732A"/>
				</radialGradient>
				<path class="st4" d="M36.41,14.33"/>
				<path class="st5" d="M21.57,35.71"/>
				<path class="st5" d="M35.96,18.26"/>
				<path class="st6" d="M17.92,27.14c-0.04-2.33,0.34-7.58,1.34-11.85c0.39-1.67,0.78-3.11,1.36-4.07l0,0
					c0.54-0.95,1.55-1.05,1.55,0.37C22.16,14.23,20.41,21.58,17.92,27.14L17.92,27.14z"/>
				<path class="st6" d="M33.12,27.99c0.03-3.48,0.5-10.43,1.88-12.8l0,0c0.4-0.67,1.02-0.52,1.14,0.18
					C36.27,16.1,36.15,20.38,33.12,27.99L33.12,27.99z"/>
				<path class="st6" d="M37.03,37.28c0.65,0.35,1.39,0.47,2.17,0.41c1.56-0.11,3.25-0.85,4.5-1.77c0.59-0.44,1.08-0.95,1.41-1.39
					c-0.33-0.08-0.62-0.18-0.89-0.31c-1.96-0.91-2.48-2.86-2.48-2.86s-0.18,0.42-0.56,1.08c-0.19,0.33-0.43,0.72-0.72,1.14
					c-0.49,0.72-1.14,1.55-1.94,2.37C38.07,36.4,37.57,36.85,37.03,37.28z"/>
				<path class="st6" d="M21.57,35.71c0.62,0.45,1.3,0.8,2,1.01c1.69,0.51,3.59,0.21,5.46-1.61c0.46-0.45,0.92-0.99,1.37-1.64
					c0.17-0.24,0.34-0.5,0.51-0.78c-1.5-1.27-2.57-3.28-2.55-4.88c0,0-0.36,0.74-1.09,1.84c-0.3,0.46-0.67,0.98-1.1,1.54
					c-0.83,1.08-1.9,2.28-3.21,3.41C22.52,34.99,22.06,35.36,21.57,35.71z"/>
				<linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="21.1734" y1="36.2789" x2="30.9362" y2="36.2789">
					<stop  offset="0" style="stop-color:#000000"/>
					<stop  offset="1" style="stop-color:#000000;stop-opacity:0"/>
				</linearGradient>
				<path class="st7" d="M30.94,39.76c-3.57,0.46-7.25-0.65-9.76-3.77l0.4-0.28c0.62,0.45,1.3,0.8,2,1.01
					c1.69,0.51,3.59,0.21,5.46-1.61c0.46-0.45,0.92-0.99,1.37-1.64c0.17-0.24,0.34-0.5,0.51-0.78L30.94,39.76z"/>
				<linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="36.3536" y1="37.219" x2="45.1094" y2="37.219">
					<stop  offset="0" style="stop-color:#000000"/>
					<stop  offset="1" style="stop-color:#000000;stop-opacity:0"/>
				</linearGradient>
				<path class="st8" d="M45.11,39.76c-0.9,0.15-1.88,0.19-2.95,0.1c-2.11-0.18-3.69-0.75-5.8-2.11l0.68-0.48
					c0.65,0.35,1.39,0.47,2.17,0.41c1.56-0.11,3.25-0.85,4.5-1.77c0.59-0.44,1.08-0.95,1.41-1.39L45.11,39.76z"/>
			</g>
		</g>
	</g>
`]
